export class Constant {
  public static readonly HTTP_TIMEZONE_HEADER: string = 'X-Timezone';

  public static readonly HTTP_UTC_OFFSET_HEADER: string = 'X-UTC-Offset';

  public static readonly HTTP_APP_LANGUAGE_HEADER: string = 'X-AppLanguage';

  public static readonly HTTP_CUSTOM_HANDLE_ERROR_CODES: string = 'X-Custom-Handle-Error-Codes';

  public static readonly HTTP_SILENT_MODE: string = 'X-Silent-Mode';

  public static readonly HTTP_IGNORE_ERROR: string = 'X-Ignore-Error';

  public static readonly HTTP_APP_VERSION_HEADER: string = 'X-AppVersion';


  // Patterns
  // eslint-disable-next-line max-len
  public static readonly PHONE_NUMBER_PATTERN: RegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

  public static readonly EMAIL_PATTERN: RegExp = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  public static readonly PERSONAL_NAME_PATTERN: RegExp = /^[a-zA-ZÀ-ž0-9\s-']*$/;

  public static readonly MAC_ADDRESS_PATTERN: RegExp = /^[0-9a-fA-F]{1,2}([\.:-])(?:[0-9a-fA-F]{1,2}\1){4}[0-9a-fA-F]{1,2}$/;

}
