import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  template: `
    <a [routerLink]="redirection ? [''] : []">
      <svg-icon src="assets/image/logo.svg"></svg-icon>
    </a>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
  @Input()
  public redirection: boolean = true;
}
